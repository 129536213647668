import React from "react";
import { IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import styles from "./Portail.module.css";

const Portail = () => {
  const navigate = useNavigate();

  const handleSigmaChoice = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/search");
    } else {
      navigate("/login/sigma");
    }
  };

  const handleImportPdf = () => {
    navigate("/import");
  };

  const handleFormulaireLibre = () => {
    navigate("/form");
  };

  return (
    <div className={styles.container}>
      <img
        src="/images/logo.jpg"
        alt="SDI logo"
        className={styles.logo}
        onClick={() => navigate("/")}
      />
      <div className={styles.card}>
        <h1 className={styles.h1}>Portail</h1>
        <div className={styles.optionsContainer}>
          <div className={styles.optionBox} onClick={handleSigmaChoice}>
            <IconButton sx={{ p: 0, color: "rgb(31, 98, 127)" }} size="large">
              <AccountCircleIcon style={{ fontSize: 60 }} />
            </IconButton>
            <Typography variant="subtitle1" align="center" className={styles.iconLabel}>
              Sigma
            </Typography>
          </div>
          <div className={styles.optionBox} onClick={handleImportPdf}>
            <IconButton sx={{ p: 0, color: "rgb(31, 98, 127)" }} size="large">
              <PictureAsPdfIcon style={{ fontSize: 60 }} />
            </IconButton>
            <Typography variant="subtitle1" align="center" className={styles.iconLabel}>
              NES
            </Typography>
          </div>
          <div className={styles.optionBox} onClick={handleFormulaireLibre}>
            <IconButton sx={{ p: 0, color: "rgb(31, 98, 127)" }} size="large">
              <DescriptionIcon style={{ fontSize: 60 }} />
            </IconButton>
            <Typography variant="subtitle1" align="center" className={styles.iconLabel}>
              Formulaire Libre
            </Typography>
          </div>
        </div>
      </div>
      <img
        src="/images/calldispatchlogosdi81.jpg"
        alt="SDI logo"
        className={`${styles.logo} ${styles.calldispatchLogo}`}
        onClick={() => navigate("/")}
      />
    </div>
  );
};

export default Portail;
