import React, { useState, useRef } from "react";
import { Typography, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import * as XLSX from "xlsx";
import styles from "./XlsDropPage.module.css";
import { nesToForm } from "../../helpers/mapper";


const XlsDropPage = () => {
  const navigate = useNavigate();
  const [omRows, setOmRows] = useState([]);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const processFile = (file) => {
    if (!file.name.match(/\.(xls|xlsx|XLS|XLSX)$/)) {
      alert("Veuillez déposer un fichier Excel (xls ou xlsx).");
      return;
    }
    const reader = new FileReader();
    reader.onload = (evt) => {
      const data = evt.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const firstSheet = workbook.Sheets[firstSheetName];
      const rows = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
      if (rows.length <= 1) {
        setOmRows([]);
      } else {
        // On ignore la première ligne (en-tête)
        const dataWithoutHeader = rows.slice(1).filter(row => {
          // Filtre les lignes où la colonne C (index 2) est définie et non vide
          return row[2] !== undefined && row[2].toString().trim() !== "";
        });
        setOmRows(dataWithoutHeader);
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      processFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      processFile(e.target.files[0]);
      e.target.value = null;
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  // Réinitialise la liste (supprime le fichier chargé)
  const handleReset = () => {
    setOmRows([]);
  };

  // Lorsqu'on clique sur une ligne, on convertit et on navigue vers /form
  const handleOmClick = (row) => {
    const formState = nesToForm(row);
    navigate("/form", { state: formState });
  };

  return (
    <div
      className={styles.container}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <img
        src="/images/logo.jpg"
        alt="SDI logo"
        className={styles.logo}
        onClick={() => navigate("/")}
      />
      <div className={styles.card}>
        {omRows.length > 0 ? (
          <div className={styles.omListContainer}>
            <h1 className={styles.h1}>OM Disponibles :</h1>

            <ul className={styles.omList}>
            {omRows.map((row, index) => {
                const numeroOm = row[2] ?? "";  // NUM_DOSSIER
                const nomPrenom = `${(row[4] ?? "").toUpperCase()} ${row[5] ?? ""}`.trim();
                const ville = (row[11] ?? "").toUpperCase();
                return (
                  <li
                    key={index}
                    className={styles.omItem}
                    onClick={() => handleOmClick(row)}
                  >
                    {`${numeroOm} - ${nomPrenom} - ${ville}`}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className={styles.dropContent} onClick={handleIconClick}>
            <CloudUploadIcon sx={{ fontSize: "200px" }} className={styles.dropIcon} />
            <Typography variant="h6" align="center" className={styles.dropText}>
              Déposez ou cliquez pour sélectionner votre fichier Excel
            </Typography>
          </div>
        )}
        {/* Bouton de réinitialisation (supprimer le fichier chargé) */}
        {omRows.length > 0 && (
          <div className={styles.resetContainer}>
            <IconButton
              onClick={handleReset}
              className={styles.resetButton}
              aria-label="Réinitialiser la liste"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
        {/* Input file caché pour la sélection manuelle */}
        <input
          type="file"
          accept=".xls,.xlsx"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
      <img
        src="/images/calldispatchlogosdi81.jpg"
        alt="SDI logo"
        className={`${styles.logo} ${styles.calldispatchLogo}`}
        onClick={() => navigate("/")}
      />
    </div>
  );
};

export default XlsDropPage;
