const formToModel = (values) => {
  let client = {};
  client.name = values.name;
  client.adress = values.adress;
  client.postalCode = values.postalCode;
  client.city = values.city;
  client.country = values.country;
  client.phoneNumbers = [];
  values?.tel1 && client.phoneNumbers.push(values.tel1);
  values?.tel2 && client.phoneNumbers.push(values.tel2);
  values?.tel3 && client.phoneNumbers.push(values.tel3);

  let appareil = {};
  appareil.brand = values.brand;
  appareil.model = values.model;
  appareil.serialNumber = values.serialNumber;
  appareil.purchaseDate = values.purchaseDate;

  let om = {};
  om.client = client;
  om.appareil = appareil;
  om.omNumber = values.omNumber;
  om.omType = values.omType;
  om.diagnostic = values.diagnostic;
  om.flatRate = values.flatRate;
  om.technicianEmail = values.technicianEmail;
  om.comment = values.comment;
  om.hideFlatRate = values.hideFlatRate;

  return om;
};

const sigmaToForm = (wo) => {
  let form = {};
  form.wo = wo.id ?? "";
  form.omType = "";
  form.diagnostic = wo.claim_description ?? "";
  form.flatRate = 0;
  form.technicianEmail = "";
  form.hideFlatRate = false;
  form.comment = "";
  form.name = wo.customer?.first_name + " " + wo.customer?.last_name ?? "";
  form.adress = wo.customer?.address ?? "";
  form.postalCode = wo.customer?.city_postal_code ?? "";
  form.city = wo.customer?.city_name ?? "";
  form.country = wo.customer?.country_code ?? "";
  form.tel1 = wo.customer?.phone ?? "";
  form.tel2 = "";
  form.brand = wo.device?.brand_name ?? "";
  form.model = wo.device?.model_commercial_name ?? "";
  form.serialNumber = wo.device?.serial_number ?? "";
  form.purchaseDate = wo.device?.purchase_date ?? "";

  return form;
};

/**
 * Transforme une ligne XLS (selon l'ordre des colonnes spécifié)
 * en objet form pour alimenter le formulaire.
 *
 * Ordre des colonnes attendu :
 *   0:  PROCESS
 *   1:  NOM_STATION
 *   2:  NUM_DOSSIER
 *   3:  DATE_OM
 *   4:  NOM
 *   5:  PRENOM
 *   6:  ADRESSE_CLI_1
 *   7:  ADRESSE_CLI_2
 *   8:  ADRESSE_CLI_3
 *   9:  ADRESSE_CLI_4
 *  10:  CODE_POSTAL_CLI
 *  11:  VILLE_CLI
 *  12:  TELEPHONE
 *  13:  TELEPHONE2
 *  14:  EMAIL_CLI
 *  15:  NUM_FACTURE
 *  16:  DATE_ACHAT
 *  17:  RAYON
 *  18:  MARQUE
 *  19:  FAMILLE_PRODUIT
 *  20:  LIBELLE
 *  21:  COD_ART
 *  22:  CODE_IRIS
 *  23:  COMMENTAIRE
 *  24:  NUM_PROCESS
 */
const nesToForm = (row) => {
  // Prépare l'adresse (prend la première non vide parmi les 4 champs)
  const addressFields = [row[6], row[7], row[8], row[9]];
  const finalAddress =
    addressFields.find((addr) => addr && addr.trim() !== "") || "";

  let form = {};
  form.wo = row[2] ?? "";         // NUM_DOSSIER
  form.omType = "";
  form.diagnostic = "";
  form.flatRate = 0;
  form.hideFlatRate = false;

  // Si vous souhaitez stocker l'email dans le formulaire :
  form.technicianEmail = row[14] ?? "";

  form.comment = row[23] ?? "";   // COMMENTAIRE
  form.name = `${(row[4] ?? "").toUpperCase()} ${row[5] ?? ""}`.trim(); // NOM + PRENOM
  form.adress = finalAddress;
  form.postalCode = row[10] ?? "";
  form.city = (row[11] ?? "").toUpperCase();
  form.country = "";              // Non défini dans le XLS
  form.tel1 = row[12] ?? "";      // TELEPHONE
  form.tel2 = row[13] ?? "";      // TELEPHONE2
  form.brand = row[18] ?? "";     // MARQUE
  form.model = row[20] ?? "";     // LIBELLE
  form.serialNumber = "";         // Non défini dans le XLS
  form.purchaseDate = row[16] ?? "";

  return form;
}

export { formToModel, sigmaToForm, nesToForm };
